export const actionTypes = {
  GET_HISTORY: 'GET_HISTORY',
  GET_HISTORY_PENDING: 'GET_HISTORY_PENDING',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  CLICK_SEE_ALL: 'CLICK_SEE_ALL',
  CLICK_HIDDEN_ITEM: 'CLICK_HIDDEN_ITEM'
};

export const getAllItems = () => ({
  type: actionTypes.CLICK_SEE_ALL
});
